import React, { useEffect } from "react";
import {
	HashRouter as Router,
	Redirect,
	Route,
	Switch,
  Link
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

import AboutPage from "../AboutPage/AboutPage";
import UserPage from "../UserPage/UserPage";
import LandingPage from "../LandingPage/LandingPage";
import LoginPage from "../LoginPage/LoginPage";
import RegisterPage from "../RegisterPage/RegisterPage";
import MyPeople from "../MyPeople/MyPeople";
import "./App.css";
import RolePage from "../RolePage/RolePage";
import HostDash from "../HostDash/HostDash";
import MainDisplay from "../MainDisplay/MainDisplay";
import SignupForm from "../SignupForm/SignupForm";
import MyQueuePage from "../MyQueuePage/MyQueuePage";

import MySongHistory from "../MySongHistory/MySongHistory";






function App() {
	const dispatch = useDispatch();

	const user = useSelector((store) => store.user);

	useEffect(() => {
		dispatch({ type: "FETCH_USER" });
	}, [dispatch]);

	return (
		<Router>
			<div>
				<Nav />
				<Switch>
					{/* Visiting localhost:3000 will redirect to localhost:3000/home */}
					<Redirect exact from="/" to="/home" />

					{/* Visiting localhost:3000/about will show the about page. */}
					<Route
						// shows AboutPage at all times (logged in or not)
						exact
						path="/about"
					>
						<AboutPage />
					</Route>

					{/* For protected routes, the view could show one of several things on the same route.
            Visiting localhost:3000/user will show the UserPage if the user is logged in.
            If the user is not logged in, the ProtectedRoute will show the LoginPage (component).
            Even though it seems like they are different pages, the user is always on localhost:3000/user */}
					<ProtectedRoute
						// logged in shows UserPage else shows LoginPage
						exact
						path="/user"
					>
						<UserPage />
					</ProtectedRoute>

					{/* ---------------------------   DOWN  ------------------------------------- */}
					<ProtectedRoute exact path="/host-dash">
						<HostDash />
					</ProtectedRoute>

					<ProtectedRoute exact path="/main-display">
						<MainDisplay />
					</ProtectedRoute>

					<ProtectedRoute exact path="/signup">
						<SignupForm />
					</ProtectedRoute>

					<ProtectedRoute exact path="/my-queue">
						<MyQueuePage />
					</ProtectedRoute>

					<ProtectedRoute exact path="/my-history">
						<MySongHistory />
					</ProtectedRoute>

					{/* ---------------------------   UP  ------------------------------------- */}

					<Route exact path="/login">
						{user.id ? (
							// If the user is already logged in,
							// redirect to the /user page
							<Redirect to="/user" />
						) : (
							// Otherwise, show the login page
							<LoginPage />
						)}
					</Route>

					<Route exact path="/registration">
						{user.id ? (
							// If the user is already logged in,
							// redirect them to the /user page
							<Redirect to="/user" />
						) : (
							// Otherwise, show the registration page
							<RegisterPage />
						)}
					</Route>

					<Route exact path="/home">
						{user.id ? (
							// If the user is already logged in,
							// redirect them to the /user page
							<Redirect to="/user" />
						) : (
							// Otherwise, show the Landing page
							<LandingPage />
						)}
					</Route>
					<Route exact path="/mypeople">
						{user.id ? (
							// If the user is already logged in,
							// redirect them to the /user page
							<Redirect to="/user" />
						) : (
							// Otherwise, show the registration page
							<MyPeople />
						)}
					</Route>

					{/* If none of the other routes matched, we will show a 404. */}
					<Route>
						<h1>404</h1>
					</Route>
				</Switch>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
